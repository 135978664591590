<template>
	<div :class="['ion-no-border', typeLanding ? 'ion-hide-md-down' : '']">
		<div class="header-promotional" v-if="user">
			<SbPromotionalHeader />
		</div>
		<header class="header-web">
			<!-- Header web Login -->
			<div class="header-web__wrapper header-web__wrapper-login">
				<div class="header-web__item">
					<router-link to="/">
						<ion-img
							:src="
								require('@/assets/images/superbeauty-full-purple.webp')
							"
							class="header-web__img"
							alt="Superbeauty">
						</ion-img>
					</router-link>
				</div>
				<div class="header-web__item header-web__location">
					<template v-if='!isGuest'>
						<SbIcon icon="pin-location-black.svg" />
						<div class="header-web__location__city">
							<template v-if="isSeller">
								<SbLocationAddress
									@click="router.push({ name: 'SelectCustomer' })"
									title="Entrega a:"
									flex-direction="column"
									align-items="flex-start"
									:content="customerSelectedName"
								/>
							</template>
							<template v-else>
								{{ user.customer.city ?? '' }}
							</template>
						</div>
					</template>
				</div>
				<div class="header-web__item header-web__search">
					<SbMainSearchWeb />
				</div>
				<div class="header-web__item header-web__profile">
					<template v-if='isGuest'>
						<SbButton
							style="margin-left: auto; font-weight: 500"
							mode="fill"
							size="medium"
							width="99px"
							@click="router.push({ name: 'Login' })">
							Ingresar
						</SbButton>
					</template>
					<template v-else>
						<SbHeaderProfile />
					</template>
				</div>
				<div class="header-web__item header-web__cart">
					<SbCart color="#000000" :fullMode="true" />
				</div>
			</div>
		</header>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"
import { isPlatform, IonImg } from "@ionic/vue"
import SbButton from "@/components/SbButton.vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import SbMainSearchWeb from "../SbMainSearchWeb.vue"
import { useUserRole } from "@/services/useUserRole"
import SbIcon from "@/components/icons/SbIcon.vue"
import SbHeaderProfile from "./SbHeaderProfile.vue"
import SbCart from "../cart/SbCart.vue"
import SbPromotionalHeader from "./SbPromotionalHeader.vue"
import SbLocationAddress from "../SbLocationAddress.vue"

export default defineComponent({
	name: "SbHeader",
	components: {
		SbButton,
		SbMainSearchWeb,
		IonImg,
		SbIcon,
		SbHeaderProfile,
		SbCart,
		SbPromotionalHeader,
		SbLocationAddress
	},
	props: {
		title: String,
		searchBar: {
			type: Boolean,
			default: true,
		},
		iconCart: {
			type: Boolean,
			default: true,
		},
		backgroundColor: {
			type: String,
			default: "#fff",
		},
		typeLanding: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const router = useRouter()
		const store = useStore()
		const user = computed(() => store.getters["auth/getUser"])
		const customer = computed(() => store.getters["auth/getCustomerConfiguredBySeller"])
		const { isSeller, isGuest } = useUserRole()

		const gotToCart = () => {
			router.push({
				name: "Cart",
			})
		}

		const customerSelectedName = computed(() => {
			if (customer.value) {
				return `${customer.value?.name}`
			}
			return "Seleccionar cliente"
		})

		return {
			store,
			isPlatform,
			router,
			gotToCart,
			user,
			isSeller,
			isGuest,
			customerSelectedName,
		}
	},
})
</script>
<style lang="scss" scoped>
.header-web {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 80px;
	left: 0px;
	top: 0px;
	padding: 0 16px;
	z-index: 100;
	background: #ffffff;
	border-bottom: 1px solid #dfe0e4;
}
.header-web__wrapper {
	max-width: 1240px;
	width: 100%;
}

.header-web__wrapper-login {
	display: grid;
	grid-template-areas: "header-image header-location header-search header-profile header-cart";
	grid-template-columns:
		140px minmax(130px, 150px) minmax(180px, 690px) minmax(102px, 120px)
		90px;
	gap: 25px;
	align-items: center;
}
.header-web__img {
	width: 141px;
}
.header-web__location {
	/* grid-area: header-location; */
	display: flex;
	/* justify-content: center; */
	align-items: center;
	&__city {
		margin-left: 8px;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}
}
.header-web__cart {
	display: flex;
	justify-content: flex-end;
}
.wrapper-button {
	display: flex;
	justify-content: flex-end;
}
.header__icon-back {
	margin-left: 20px;
	width: 10px;
}
.header__icon-cart {
	margin-right: 20px;
}
.header__title {
	font-weight: 600;
	font-size: 2rem;
	line-height: 3rem;
	text-align: center;
}
.bg__white {
	background-color: white;
}

/* ------ Header No Login ----- */
.header-web__wrapper-no-login {
	display: flex;
	align-items: center;
}
.header-web__nav__list {
	display: flex;
}
.header-web__nav__item {
	list-style: none;
	margin-right: 40px;
}
.header-web__nav__link {
	text-decoration: none;
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
}
.header-web__item--button {
	margin-left: auto;
}
.header-promotional {
	width: 100%;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2e16e02f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-web" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbMainSearch = _resolveComponent("SbMainSearch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SbMainSearch, {
      class: "search-web__input-search",
      placeholder: "Buscar un producto",
      background: "#ffffff",
      focus: false,
      shadow: true,
      onOnClear: _ctx.handlerClear,
      onOnSearching: _ctx.handlerSearching
    }, null, 8, ["onOnClear", "onOnSearching"])
  ]))
}
<template>
	<div class="search-web">
		<SbMainSearch
			class="search-web__input-search"
			placeholder="Buscar un producto"
			background="#ffffff"
			:focus="false"
			:shadow="true"
			@onClear="handlerClear"
			@onSearching="handlerSearching"
		/>
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import SbMainSearch from "@/components/SbMainSearch.vue"
import { useRouter } from "vue-router"

export default defineComponent({
	name: "SbMainSearchWeb",
	components: {
		SbMainSearch,
	},
	setup() {
		const router = useRouter()

		const handlerSearching = () => {
			router.push({
				name: "ViewResultsSearch",
			})
		}

		const handlerClear = () => {
			router.push({ name: 'Landing'})
		}

		return {
			handlerClear,
			handlerSearching
		}
	},
})
</script>
<style lang="scss" scoped>
.search-web {
	position: relative;
	max-width: 698px;
	width: 100%;
}

.search-web__searching-text {
	font-size: 16px;
	position: absolute;
	color: #000000;
	text-align: center;
	padding: 16px;
	width: 100%;
	border-radius: 8px;
	background: #ffffff;
	box-shadow: 0px 7px 15px rgba(104, 104, 104, 0.12);
}
.search-web__results {
	position: absolute;
	margin-top: 6px;
	z-index: 9;
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 7px 15px rgba(104, 104, 104, 0.12);
	border-radius: 8px;
}

.search-web__results__list {
	padding: 24px 12px;
	list-style: none;
	max-height: 284px;
	overflow-y: auto;
	margin: 0px;
}

.search-web__results__list-item {
	min-height: 44px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.search-web__results__list-item__image {
	object-fit: cover;
	height: 44px;
	width: 44px;
	overflow: hidden;
	border-radius: 9px;
	margin-right: 26px;
}

.search-web__results__list-item__image img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.search-web__results__list-item__text {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #000000;
}

.search-web__input-search {
	width: 100%;
}
</style>

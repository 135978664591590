import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a4132e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sb-button__wrapper" }
const _hoisted_2 = { class: "sb-button__icon-left" }
const _hoisted_3 = { class: "sb-button__text" }
const _hoisted_4 = { class: "sb-button__icon-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    fill: _ctx.outline ? 'outline' : 'clear',
    class: _normalizeClass(["sb-button", [_ctx.size, _ctx.mode, _ctx.disabled ? 'disabled' : '']]),
    disabled: _ctx.disabled ? true : false,
    style: _normalizeStyle(_ctx.cssProps)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "iconLeft", {}, undefined, true)
        ]),
        _createElementVNode("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("span", _hoisted_4, [
          _renderSlot(_ctx.$slots, "iconRight", {}, undefined, true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["fill", "class", "disabled", "style"]))
}
<template>
	<div class="onboarding__container">
		<swiper
			class="onboarding"
			:slides-per-view="1"
			:space-between="0"
			:modules="modules"
			:autoplay="false"
			:keyboard="true"
			:pagination="true"
			:loop="false"
			:navigation="true"
			@swiper="getInstanceSwiper">
			<swiper-slide>
				<div class="onboarding__wrapper">
					<div class="onboarding__wrapper-image">
						<img
							class="onboarding__image"
							src="https://superbeautyapp-dev.s3.amazonaws.com/images/icons/compra-24-horas.png"
							alt="¡Olvida los agotados!" />
					</div>
					<div class="onboarding__text">
						<h2 class="onboarding__title">¡Olvida los agotados!</h2>
						<p class="onboarding__paragraph">
							Las mejores marcas de belleza disponibles en un solo
							lugar.
						</p>
					</div>
				</div>
			</swiper-slide>
			<swiper-slide>
				<div class="onboarding__wrapper">
					<div class="onboarding__wrapper-image">
						<img
							class="onboarding__image"
							src="https://superbeautyapp-dev.s3.amazonaws.com/images/icons/lo-que-mas-compro-V4.png"
							alt="Sin monto mínimo de compra" />
					</div>
					<div class="onboarding__text">
						<h2 class="onboarding__title">
							Sin monto mínimo de compra.
						</h2>
						<p class="onboarding__paragraph">
							Precios al por mayor compra sin monto mínimo.
						</p>
					</div>
				</div>
			</swiper-slide>
		</swiper>
		<div class="onboarding__controls">
			<SbButton
				mode="fill"
				size="large"
				:disabled="false"
				@click="nextSlide"
				>Siguiente</SbButton
			>
			<button class="onboarding__dismiss" @click="dismissOnboarding">
				Saltar
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Autoplay, Keyboard, Pagination, Controller, Navigation } from "swiper"
import SbButton from "@/components/SbButton.vue"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/keyboard"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "@ionic/vue/css/ionic-swiper.css"

export default defineComponent({
	name: "SbOnBoarding",
	components: {
		Swiper,
		SwiperSlide,
		SbButton,
	},
	setup(props, { emit }) {
		const swiperInstance: any = ref(null)

		const getInstanceSwiper = (swiper: any) => {
			swiperInstance.value = swiper
		}
		const nextSlide = () => {
			if (swiperInstance.value) {
				if (
					swiperInstance.value.activeIndex + 1 ==
					swiperInstance.value.slides.length
				) {
					dismissOnboarding()
				}
			}
			// eslint-disable-next-line
			;(document as any).querySelector(".swiper-button-next").click()
		}

		const dismissOnboarding = () => {
			emit("onBoardingClosed")
		}

		onMounted(() => {
			// eslint-disable-next-line
			;(document as any).querySelector(
				".swiper-button-prev"
			).style.display = "none"
			// eslint-disable-next-line
			;(document as any).querySelector(
				".swiper-button-next"
			).style.display = "none"
		})

		return {
			modules: [Autoplay, Keyboard, Pagination, Controller, Navigation],
			nextSlide,
			dismissOnboarding,
			getInstanceSwiper,
		}
	},
})
</script>
<style lang="scss" scoped>
.onboarding__container {
	height: 100%;
	background: #fff;
	z-index: 999;
	padding: 16px;
	overflow: scroll;
	position: relative;
}
.onboarding {
	padding-bottom: 30px;
	margin-bottom: 10px;
}
.onboarding__wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.onboarding__wrapper-image {
	margin-top: 20px;
	max-width: 400px;
	width: 300px;
	height: 300px;
	width: 100%;
	display: flex;
	align-items: flex-end;
}
.onboarding__wrapper .onboarding__image {
	width: 100%;
	object-fit: contain;
}
.onboarding__text {
	padding-top: 20px;
}
.onboarding__title {
	font-size: 8vmin;
	font-style: normal;
	font-weight: 700;
	line-height: 9vmin;
	letter-spacing: 0em;
	text-align: center;
	margin-top: 0px;
	margin-bottom: 10%;
	color: #000000;
}
.onboarding__paragraph {
	font-size: 4vmin;
	font-style: normal;
	font-weight: 300;
	line-height: 6vmin;
	letter-spacing: 0em;
	text-align: center;
	color: #a4a5ab;
	margin-bottom: 10%;
}
.onboarding__controls {
	width: 100%;
	max-width: 500px;
	margin: 50px auto 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 2%;
}
.onboarding__dismiss {
	background: #ffffff;
	border: none;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
	color: #000;
	margin-top: 32px;
}
@media screen and (min-width: 300px) {
	// .onboarding__wrapper-image {
	//     padding: 30px;
	// }
	// .onboarding__text {
	//     padding: 0 60px;
	// }
	// .onboarding__wrapper .onboarding__wrapper-image .onboarding__image {
	//     width: 148px;
	// }
	// .onboarding__title {
	//     margin-bottom: 30px;
	// }
}

@media screen and (min-width: 720px) {
	// .onboarding__wrapper-image {
	//     padding: 30px;
	// }
	.onboarding__title {
		font-size: 40px;
		margin-bottom: 15px;
	}
	.onboarding__paragraph {
		font-size: 16px;
	}
	// .onboarding__controls {
	//     justify-content: space-between;
	//     flex-direction: row-reverse;
	// }
}
@media (orientation: landscape) {
	// .onboarding__controls {
	//     justify-content: space-between;
	//     flex-direction: row-reverse;
	// }
}
</style>

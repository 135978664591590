<template>
	<!-- timer of promotional header -->

	<div
		v-show="showPromotionalHeader"
		class="mensaje mensajePromoTop promotional-header"
		id="promotionalHeader">
		<div
			class="promotional-header__wrapper"
			:style="{ background: design.backgroundTimer }">
			<div class="mensaje-container">
				<div class="mensaje-close" @click="closePromotionalHeader">
					<SbIcon icon="close-filled.svg" />
				</div>
				<div v-show="timer.active" class="mensaje__counter">
					<div class="counter">
						<div
							class="counter__text"
							:style="{
								color: design.tagText,
							}">
							¡{{ design.timerTitle }}! Termina en
						</div>
						<div class="counter__timer">
							<div
								class="counter__timer__item simply-section simply-amount simply-days"
								:style="{
									color: design.timerText,
									background: design.timerBackground,
								}">
								{{ timer.days }}d
							</div>
							<div
								class="counter__timer__item simply-section simply-amount simply-hours"
								:style="{
									color: design.timerText,
									background: design.timerBackground,
								}">
								{{ timer.hours }}h
							</div>
							<div
								class="counter__timer__item simply-section simply-amount simply-minutes"
								:style="{
									color: design.timerText,
									background: design.timerBackground,
								}">
								{{ timer.minutes }}m
							</div>
							<div
								class="counter__timer__item simply-section simply-amount simply-seconds"
								:style="{
									color: design.timerText,
									background: design.timerBackground,
								}">
								{{ timer.seconds }}s
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "vue"
import { promotionalHeader, useMarketing } from "@/services/useMarketing"
import SbIcon from "../icons/SbIcon.vue"

export default defineComponent({
	name: "SbPromotionalHeader",
	components: {
		SbIcon,
	},
	setup() {
		const { getPromotionalHeader } = useMarketing()
		const showPromotionalHeader = ref(
			localStorage.getItem("showPromotionalHeader") === "true"
		)
		const design = computed(() => {
			return {
				backgroundTimer: promotionalHeader.value?.design.timerBg,
				timerTitle: promotionalHeader.value?.title,
				timerText: promotionalHeader.value?.design.itemTimeText,
				timerBackground: promotionalHeader.value?.design.itemTimeBg,
				tagText: promotionalHeader.value?.design.tagText,
				tagBackground: promotionalHeader.value?.design.tagBg,
			}
		})
		const timer = ref({
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			active: false,
		})
		const defineTimer = (date: any) => {
			if (!date)
				return {
					days: 0,
					hours: 0,
					minutes: 0,
					seconds: 0,
					active: false,
				}
			const endDate = new Date(date)
			const now = new Date().getTime()
			const distance = endDate.getTime() - now
			const days = Math.floor(distance / (1000 * 60 * 60 * 24))
			const hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			)
			const minutes = Math.floor(
				(distance % (1000 * 60 * 60)) / (1000 * 60)
			)
			const seconds = Math.floor((distance % (1000 * 60)) / 1000)

			if (promotionalHeader.value?.timer === false) {
				return {
					days: 0,
					hours: 0,
					minutes: 0,
					seconds: 0,
					active: false,
				}
			}
			const active = distance > 0

			return {
				days,
				hours,
				minutes,
				seconds,
				active,
			}
		}
		const updateTimer = () => {
			timer.value = defineTimer(promotionalHeader.value?.end_date)
		}
		watch(promotionalHeader, () => {
			updateTimer()
		})

		setInterval(updateTimer, 1000)

		onMounted(() => {
			getPromotionalHeader()
			localStorage.setItem("showPromotionalHeader", "true")
			updateTimer()
		})

		const closePromotionalHeader = () => {
			showPromotionalHeader.value = false
			localStorage.setItem("showPromotionalHeader", "false")
		}

		return {
			design,
			timer,
			showPromotionalHeader,
			closePromotionalHeader,
		}
	},
})
</script>
<style>
.promotional-header__wrapper {
	width: 100%;
}

.mensaje-container {
	position: relative;
	max-width: 1200px;
	margin: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0px;
	height: 50px;
}

.mensaje__counter {
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 5px;
}

.counter {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.counter__text {
	font-size: 11px;
	letter-spacing: 0.5px;
	text-align: center;
	margin-right: 10px;
	padding: 2.5px 10px;
	border-radius: 5px;
	font-family: "Poppins";
	font-weight: bold;
}

.counter__timer {
	display: flex;
	text-align: center;
	margin-right: 10px;
	font-family: "Poppins";
	padding: 2.5px 10px;
	font-weight: bold;
}

.counter__timer__item,
.simply-section {
	width: 35px;
	letter-spacing: 1px;
	font-size: 12px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 5px;
	border-radius: 5px;
	font-weight: 600;
}

.mensaje__container__texto {
	text-align: center;
	margin: 0 10px 0 10px;
	color: #fff;
	margin-right: 30px;
	font-size: 13px;
	line-height: 13px;
}

.mensaje__container__texto__shipping {
	font-weight: 600;
}

.mensaje-close {
	position: absolute;
	right: 10px;
	color: #fff;
	font-size: 5px;
	cursor: pointer;
	z-index: 3;
}
@media (min-width: 768px) {
	.mensaje__container__texto {
		font-size: 14px;
		line-height: 14px;
	}
	.mensaje__container__texto__shipping {
		font-size: 14px;
		line-height: 14px;
	}
	.counter__text {
		font-size: 14px;
		line-height: 14px;
		padding: 5px 10px;
	}
	.counter__timer {
		font-size: 14px;
		line-height: 14px;
		padding: 5px 10px;
	}
	.counter__timer__item,
	.simply-section {
		width: 40px;
		height: 30px;
		font-size: 14px;
		line-height: 14px;
	}
	.mensaje-close {
		font-size: 14px;
	}
}
</style>

import axios, { AxiosInstance } from "axios"
import store from "../store/index"
import { ref } from "vue"
import { formatDateWithDash } from "../utils"

const apiClient: AxiosInstance = axios.create({
	baseURL: process.env.VUE_APP_API_HOST_AUTHENTICATION,
})

apiClient.interceptors.request.use((config: any) => {
	const token = store.getters["auth/getToken"]
	config.headers.common["token"] = token
	return config
})

export interface DataCustomer {
	rol: string
	name: string
	last_name: string
	phone: number | string
	email: string
	password?: string
	trade_type: string
	business_name: string
	tradename: string
	person_type: string
	document_type: string
	document_number: string
	billing_name: string
	country: string
	country_id: number
	state: string
	state_id: number
	city: string
	city_id: number
	gender?: string
	address: string
	location: [number, number]
	postal_code: string | number | null
	additional_address: string
	business_type: string
	stores_number: number
	instagram: string
	instagram_phone: string
	web: string
	phone_validation?: string
	shipping_addresses: [
		{
			order: number
			country: string
			country_id: number
			state: string
			state_id: number
			city: string
			city_id: number
			address: string
			additional_address: string
		}
	]
	seller_id?: string | null,
	fecha_lead?: string
}

export type DataCustomerPartial = Pick<DataCustomer, 'name' | 'last_name' | 'phone' | 'email' | 'trade_type' | 'document_number' | 'gender' | 'fecha_lead'> 

export interface DataUserStatus {
	phone: string
}
export interface DataSendCodeAuth {
	country: number // 57, 1, etc.
	phone: number
	channel: string
}
export interface DataCheckCodeAuth {
	country: number
	phone: number
	code: number
	_id: string
}
export interface DataSendCodeVerify {
	phone: string
	channel: string
}
export interface DataCheckCodeVerify {
	phone: string
	code: string
	_id: number
}
export interface DataLoginWithEmailAndPassword {
	email: string | null
	password: string | null
}
export const phoneCodeCountry = ref(57)
export const loginInputPhone = ref()
export const loginInputLegal = ref(true)
export const loginInputChannel = ref("")
export const loginInputEmail = ref<string | null>(null)
export const loginInputPassword = ref<string | null>(null)

export function useAuth() {
	/**
	 * Crear usuario customer
	 * @param {DataCustomer} customer data object
	 */
	type payloadDataCustomer = {
		dataCustomer: DataCustomer | DataCustomerPartial,
		from_landing?: boolean 
	}
	const saveCustomer = ({ dataCustomer, from_landing = false}: payloadDataCustomer ): Promise<object> => {
		const path = from_landing ? "/v3/auth/register?from_landing=1" : "/v3/auth/register"
		// Obtener fecha actual de registro y configurar en dataCustomer
		const currentDate = formatDateWithDash(new Date())
		dataCustomer.fecha_lead = currentDate

		return apiClient.post(path, dataCustomer)
	}

	const userStatus = (phone: DataUserStatus): Promise<object> => {
		return apiClient.post("/verify/user-status", phone)
	}

	/**
	 * Verificar telefono celular para el registro
	 * @param { Object } payload
	 * @param { string } payload.phone numero de telefono celular con codigo de país como prefíjo
	 * @param { string } payload.channel medio de envio del código
	 * @returns Object Promise
	 */
	const sendCodeVerify = (payload: DataSendCodeVerify): Promise<object> => {
		return apiClient.post("/verify/verify", payload)
	}

	const checkCodeVerify = (payload: DataCheckCodeVerify) => {
		return apiClient.post("/verify/check", payload)
	}

	/**
	 * Envíar codigo para inicio de sesión
	 * @param { Object } dataLogin
	 * @param { number } dataLogin.country codigo de pais
	 * @param { string } dataLogin.phone número de teléfono celular
	 * @param { string } dataLogin.channel medio de envío del codigo
	 * @returns Object Promise
	 */
	const sendCodeAuth = (dataLogin: DataSendCodeAuth): Promise<object> => {
		return apiClient.post("/auth/verify", dataLogin)
	}

	const checkCodeAuth = (payload: DataCheckCodeAuth) => {
		return apiClient.post("/auth/check", payload)
	}

	const getRefreshToken = (payload: any) => {
		apiClient.interceptors.request.use((config: any) => {
			config.headers.common["token"] = payload.token
			config.headers.common["refreshToken"] = payload.refreshToken
			return config
		})

		return apiClient.post("/auth/refresh-token")
	}

	// Clear data input login and check legal
	const setDataInputLogin = (payload: { phone: number; legal: boolean }) => {
		loginInputPhone.value = payload.phone
		loginInputLegal.value = payload.legal
	}

	// Clear data input login and check legal
	const clearDataInputLogin = () => {
		loginInputPhone.value = undefined
		loginInputChannel.value = ""
		loginInputEmail.value = ""
		loginInputPassword.value = ""
	}

	const loginWithEmailAndPassword = (
		dataLogin: DataLoginWithEmailAndPassword
	) => {
		return apiClient.post("/v3/auth/login", dataLogin)
	}

	/**
	 * `getUser` get information of user profile logued in
	 * @returns data user profile or error
	 */
	const getUser = async () => {
		try {
			const response = await apiClient.get("/auth/me")
			if (response.status === 200) {
				return response.data
			}
		} catch (err: any) {
			return err
		}
	}

	const deleteUserAccount = async () => {
		try {
			const response = await apiClient.delete("/customer/remove")
			if (response.status === 200) {
				return {
					ok: true,
					message: "Su cuenta de cliente ha sido eliminada.",
				}
			} else {
				return {
					ok: false,
					message:
						"Nuestro servidor está experimentando dificultades momentáneas.",
				}
			}
		} catch (error: any) {
			if (error.response.data.message === "User no found") {
				return {
					ok: false,
					message: "Cliente no encontrado",
				}
			}

			return {
				ok: false,
				message:
					"Ha ocurrido un error al intentar eliminar el cliente.",
			}
		}
	}

	const recoveryPassword = async (
		email: string
	): Promise<{ error: boolean; message: string }> => {
		try {
			const response = await apiClient.post("/recovery-password", {
				email,
			})
			return {
				error: false,
				message: response.data.results.message,
			}
		} catch (error: any) {
			return {
				error: true,
				message: error.response.data.results.message,
			}
		}
	}

	const changePassword = async ({
		newPassword,
		recoveryToken,
	}: {
		newPassword: string
		recoveryToken: string
	}): Promise<{ error: boolean; message: string }> => {
		try {
			const response = await apiClient.put("/change-password", {
				newPassword,
				recoveryToken,
			})
			return {
				error: false,
				message: response.data.results.message,
			}
		} catch (error: any) {
			return {
				error: true,
				message: error.response.data.results.message,
			}
		}
	}

	return {
		saveCustomer,
		userStatus,
		sendCodeVerify,
		checkCodeVerify,
		sendCodeAuth,
		checkCodeAuth,
		getRefreshToken,
		setDataInputLogin,
		clearDataInputLogin,
		getUser,
		loginWithEmailAndPassword,
		deleteUserAccount,
		recoveryPassword,
		changePassword,
	}
}

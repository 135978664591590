<template>
	<template v-if='showPopup'>
		<template v-if="popupMobile">
			<div class='ion-hide-sm-up'>
				<ion-backdrop
					class='ion-hide-sm-up'
					:style="{ display: showPopup ? 'block' : 'none' }">
				</ion-backdrop>
				<div class="modal_popup_welcome">
					<div class="modal_popup_welcome_container">
						<span class="modal_popup_welcome_close">
							<SbIcon icon="close.svg" />
						</span>
						<div class="modal_popup_welcome_wrapper">
							<router-link
								v-if="popupMobile?.app_link"
								class="modal_popup_welcome__link"
								:to="`${popupMobile?.app_link}`"></router-link>
							<img
								class="modal_popup_welcome__image"
								:src="popupMobile?.medium"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-if="popup">
			<div class="ion-hide-sm-down">
				<ion-backdrop
					:style="{ display: showPopup ? 'block' : 'none' }">
				</ion-backdrop>

				<div class="modal_popup_welcome">
					<div class="modal_popup_welcome_container">
						<span class="modal_popup_welcome_close">
							<SbIcon icon="close.svg" />
						</span>
						<div class="modal_popup_welcome_wrapper">
							<router-link
								v-if="popup?.app_link"
								class="modal_popup_welcome__link"
								:to="`${popup?.app_link}`"></router-link>
							<img
								class="modal_popup_welcome__image"
								:src="popup?.medium"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</template>
</template>

<script lang="ts">
import { PropType, defineComponent, onMounted, ref, watch } from "vue"
import { IonBackdrop } from "@ionic/vue"
import { Banner } from "@/types"
import SbIcon from "./icons/SbIcon.vue"

export default defineComponent({
	name: "SbModal",
	components: {
		IonBackdrop,
		SbIcon,
	},
	props: {
		popup: {
			type: Object as PropType<Banner>,
			default: null,
		},
		popupMobile: {
			type: Object as PropType<Banner>,
			default: null,
		}
	},
	setup(props) {
		const showPopup = ref(false)
		watch(
			() => props.popup,
			() => {
				showPopup.value = true
			}
		)

		const openPopup = () => {
			showPopup.value = true
		}

		// Método para cerrar el Popup
		const closePopup = () => {
			showPopup.value = false
		}

		onMounted(() => {
			if (!props.popup) {
				showPopup.value = false
			}

			window.onclick = function (event: any) {
				if (
					!event.target.matches(".modal_popup_welcome__image") &&
					!event.target.matches(".modal_popup_welcome__link")
				) {
					showPopup.value = false
				}
			}
		})

		return {
			showPopup,
			openPopup,
			closePopup,
		}
	},
})
</script>
<style lang="scss" scoped>
$popupMobile: v-bind(popupMobile);

ion-backdrop {
	opacity: 0.9;
	background: #00000065;
}

.modal_popup_welcome {
	position: absolute;
	top: 0px;
	left: 0px;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	transition: ease all 0.5s;
}

.modal_popup_welcome_container {
	max-width: 1000px;
	width: 100%;
	max-height: 90vh;
	margin: 5px;
	position: relative;

	@media (max-width: 576px) {
		@if $popupMobile {
			max-height: 100vh;
			height: 100vh;
			margin: 0px;
		}
	}
}
.modal_popup_welcome_container img {
	border-radius: 15px;
	width: 100%;
	height: 100%;
	object-fit: cover;

	@media (max-width: 576px) {
		@if $popupMobile {
			border-radius: 0;
			height: 100vh;
		}
	}
}
.modal_popup_welcome_close {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	top: 0px;
	right: 1px;
	background: #fff;
	border: none !important;
	color: #000;
	width: 30px;
	height: 30px;
	z-index: 999;
	border-top-right-radius: 12px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 8px;

	@media (min-width: 768px) {
		width: 50px;
		height: 50px;
		border-top-right-radius: 15px;
		border-top-left-radius: 11px;
		border-bottom-left-radius: 33px;
		border-bottom-right-radius: 11px;
	}
}
.modal_popup_welcome_close img {
	width: 20px;
	height: 20px;

	@media (min-width: 768px) {
		width: 26px;
		height: 26px;
	}
}
</style>

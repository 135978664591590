<template>
	<ion-row>
		<ion-col>
			<ion-row
				v-if="isSeller"
				class="item ion-justify-content-start ion-align-items-center"
				@click="router.push({ name: 'MarketplaceHome' })">
				<SbIcon icon="profile_bag.svg" color="#000" class="icon" />
				<h3 class="item__title">Tomar pedido</h3>
			</ion-row>
			<ion-row
				v-if="isSeller"
				class="item ion-justify-content-start ion-align-items-center"
				@click="router.push({ name: 'RegisterProcess' })">
				<SbIcon icon="register.svg" color="#000" class="icon" />
				<h3 class="item__title">Registrar cliente</h3>
			</ion-row>
			<ion-row
				class="item ion-justify-content-start ion-align-items-center"
				@click="router.push({ name: 'OrderHistory' })">
				<SbIcon icon="profile_history.svg" color="#000" class="icon" />
				<h3 class="item__title">Historial de pedidos</h3>
			</ion-row>
			<ion-row
				class="item ion-justify-content-start ion-align-items-center"
				@click="router.push({
					name: 'MyProfile',
					params: { name: user.name.toLowerCase() },
					hash: `#creditos`
				})">
				<SbIcon icon="profile_history.svg" color="#000" class="icon" />
				<h3 class="item__title">Mis créditos</h3>
			</ion-row>
			<ion-row
				v-if="!isSeller"
				class="item ion-justify-content-start ion-align-items-center"
				@click="router.push({ name: 'MyAddressList' })">
				<SbIcon icon="profile_location.svg" color="#000" class="icon" />
				<h3 class="item__title">Mis direcciones de entrega</h3>
			</ion-row>
			<ion-row
				class="item ion-justify-content-start ion-align-items-center"
				@click="router.push({ path: '/home/support' })">
				<SbIcon icon="profile_help.svg" color="#000" class="icon" />
				<h3 class="item__title">Ayuda</h3>
			</ion-row>
			<ion-row
				class="item ion-justify-content-start ion-align-items-center"
				@click="router.push({ name: 'TermsAndConditions' })">
				<SbIcon
					icon="terminos-y-condiciones.png"
					color="#000"
					class="icon" />
				<h3 class="item__title">Términos y condiciones</h3>
			</ion-row>
			<ion-row
				class="item ion-justify-content-start ion-align-items-center"
				@click="logout">
				<SbIcon icon="profile_exit.svg" color="#000" class="icon" />
				<h3 class="item__title">Cerrar sesión</h3>
			</ion-row>
			<ion-row class="item version__app" v-if="installedVersion">
				<h3 class="item__title">
					Versión <br />
					<span>{{ installedVersion }}</span>
				</h3>
			</ion-row>
		</ion-col>
	</ion-row>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { IonRow, IonCol } from "@ionic/vue"
import SbIcon from "../icons/SbIcon.vue"
import { useUserRole } from "../../services/useUserRole"
import { installedVersion } from "../../services/useCheckApp"

export default defineComponent({
	name: "SbProfileOptions",
	components: {
		IonRow,
		IonCol,
		SbIcon,
	},
	setup() {
		const router = useRouter()
		const store = useStore()
		const user: any = ref(store.getters["auth/getUser"])
		const initialsOfNames = ref("")
		const { isSeller, isAdminCustomer } = useUserRole()

		const logout = async () => {
			store.dispatch("auth/userLogout")
			await router.push({ name: "Landing" })
			window.location.reload()
		}

		onMounted(async () => {
			user.value = await store.getters["auth/getUser"]
			initialsOfNames.value =
				user.value.name.substring(0, 1) +
				user.value.last_name.substring(0, 1)
		})

		return {
			user,
			router,
			logout,
			initialsOfNames,
			isSeller,
			isAdminCustomer,
			installedVersion,
		}
	},
})
</script>

<style scoped>
.item {
	margin: 0;
	padding-block: 12px;
	cursor: pointer;
}
.icon {
	width: 32px;
}
.item__title {
	margin: 0;
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 1.8rem;
	padding-inline: 16px;
}
.disabled {
	color: #999;
}
.version__app {
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 50px;
	color: #000000ad;
}
</style>

<template>
	<div class="select-viewer" :style="'color:' + color">
		<span class="select-viewer-text">
			{{ title }} &nbsp;
			<strong
				:class="['select-viewer-content', content ? 'purple' : 'gray']">
				{{ content || "Seleccionar cliente" }}
			</strong>
			<!-- <SbIcon class="select-viewer-icon-arrow" icon="arrow-bottom.svg" /> -->
		</span>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue"
import { useStore } from "vuex"
// import SbIcon from "./icons/SbIcon.vue";

export default defineComponent({
	name: "SbLocationAddress",
	props: {
		color: {
			type: String,
			default: "#ffffff",
		},
		title: {
			type: String,
			default: "Entrega en:",
		},
		content: {
			type: String,
			default: "",
		},
		flexDirection: {
			type: String,
			default: "row"
		},
		alignItems: {
			type: String,
			default: "center"
		},
	},
	setup() {
		const store = useStore()
		const user: any = ref(store.getters["auth/getUser"])
		return {
			user,
		}
	},
	components: {
		// SbIcon
	},
})
</script>
<style lang="scss" scoped>
.select-viewer {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	cursor: pointer;
}
.select-viewer-text {
	display: flex;
	flex-direction: v-bind(flexDirection);
	align-items: v-bind(alignItems);
	color: #000;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 11px;
}
.select-viewer-content {
	font-weight: 500;
	line-height: 18px;
	line-height: 21px;
}
.select-viewer-content.purple {
	color: #bb29bb;
}
.select-viewer-content.gray {
	color: #c7c7c7;
}
.select-viewer-icon-arrow {
	margin-left: 8px;
	width: 14px;
}
</style>
